
import { ProgramFormula, ProgramType } from '@/types';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ProgramFormulaSpan extends Vue {
    @Prop({ required: true }) type!: ProgramType;

    @Prop({ default: 'nl-be' }) language!: string;

    get programType() {
        const ft = this.type;
        switch (ft) {
           case ProgramType.GROUP: {
                return this.language == 'nl-be' ? 'Groepsbegeleiding' : 'Accompagnement en groupe'
            }
            case ProgramType.INDIVIDUAL: {
                return this.language == 'nl-be' ? 'Individuele begeleiding' : 'Accompagnement individuel'
            }
            case ProgramType.MIXED: {
                return this.language == 'nl-be' ? 'Gemengde begeleiding' : 'Entraînement mixte'
            }
            default: {
                return this.language == 'nl-be' ? 'Onbekende begeleiding' : 'Orientation inconnue'
            }
        }
    }
} 
